import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Icon9 from "../../../static/images/partners/members/microsoft-partner.svg"
import Icon10 from "../../../static/images/partners/members/google.svg"
import { FaArrowCircleRight } from "react-icons/fa"

const PartnerAWS = () => {
  const data = useStaticQuery(graphql`
    query Images_partneraws {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Especialistas en Cloud y Amazon Web Services Partner | Qualoom</title>
        <meta name="title" content="Especialistas en Cloud y Amazon Web Services Partner | Qualoom"/>
        <meta name="description" content="Somos Advanced Consulting Partner de Amazon Web Services. Ofrecemos implantación y mantenimiento de infraestructuras Cloud en AWS y Azure.¡Infórmate!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/partner-advanced-aws/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/partner-advanced-aws/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/partner-advanced-aws/"/>
        <meta property="og:title" content="Especialistas en Cloud y Amazon Web Services Partner | Qualoom"/>
        <meta property="og:description" content="Somos Advanced Consulting Partner de Amazon Web Services. Ofrecemos implantación y mantenimiento de infraestructuras Cloud en AWS y Azure.¡Infórmate!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/partner-advanced-aws/"/>
        <meta property="twitter:title" content="Especialistas en Cloud y Amazon Web Services Partner | Qualoom"/>
        <meta property="twitter:description" content="Somos Advanced Consulting Partner de Amazon Web Services. Ofrecemos implantación y mantenimiento de infraestructuras Cloud en AWS y Azure.¡Infórmate!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/partner-advanced-aws/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}},
              {"@type":"ListItem", "position":"3", "name":"Amazon Web Services Partner", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/partner-advanced-aws/", "name": "Amazon Web Services Partner"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Amazon Web Services Partner</h1>
              <p>Advanced Consulting Partner de Amazon Web Services con más de 13 años de experiencia en proyectos de migración a la gran nube, Reseller y miembro del programa de competencias: Digital Customer Experience Competence y Migration/DevOps Competence permiten que cientos de clientes han confiado en nosotros para impulsar, asegurar y evolucionar sus servicios.​</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué es Amazon Web Services?</h2><hr></hr>
          <div className="section-main">
            <div className="section-img aws"></div>
            <div className="section-text">
              <p><b>Amazon Web Services (AWS) es una de las principales plataformas en la nube</b> más adoptada por empresas, <b>cuenta con más de 200 servicios integrales de centros de datos a nivel global</b>. Proporciona varias herramientas y servicios de Cloud computing desde almacenamiento en la nube hasta la gestión de imágenes virtuales o el desarrollo de aplicaciones móviles.</p>
            </div>
          </div>
          <br/>
          <h3>Las ventajas de AWS para tu negocio</h3>
          <ul>
            <li><b>Facilidad de uso.</b> Está diseñada para poder hospedar cualquier tipo de aplicación de forma rápida y segura.</li>
            <li><b>Flexibilidad.</b> Puedes seleccionar la plataforma de desarrollo o el modelo de programación que se adapte mejor a tu negocio.</li>
            <li><b>Rentabilidad.</b> AWS ofrece precios bajos por uso, los únicos gastos serán de aquellos servicios que quieras contratar.</li>
            <li><b>Alta escalabilidad.</b> Con las herramientas de Auto Scaling y Elastic Load Balancing, tu aplicación podrá ampliarse o reducirse según la demanda.</li>
            <li><b>Seguridad.</b> AWS cuenta con certificaciones y acreditaciones para administrar las infraestructuras de las empresas de manera segura y duradera.</li>
          </ul><br/>
          <h3>¿Cuáles son algunas de las principales herramientas o servicios que ofrece AWS?</h3>
          <p>Hay cientos de servicios en función de los casos de uso:</p>
          <ul className="list-col-3">
            <li>Cómputo</li>
            <li>Almacenamiento</li>
            <li>Redes</li>
            <li>Copias de Seguridad</li>
            <li>DevOps</li>
            <li>E-Commerce</li>
            <li>IoT</li>
            <li>Seguridad</li>
            <li>Inteligencia artificial y Machine Learning</li>
          </ul><br/>
          <p>Podemos destacar los utilizados con más frecuencia:</p>
          <ul>
            <li><b>Amazon Elastic Compute Cloud (Amazon EC2). </b>Ofrece capacidad de cómputo variable en la nube.</li>
            <li><b>Amazon Simple Storage Service (Amazon S3). </b>Proporciona una sencilla interfaz de servicios web que permite almacenar y recuperar la cantidad de datos que desee, en cualquier momento y desde cualquier parte de la web.</li>
            <li><b>Amazon Relational Database Service (Amazon RDS). </b>Facilita la configuración, el funcionamiento y el escalado de una base de datos relacional en la nube.</li>
            <li><b>Amazon CloudFront. </b>Ofrece un sistema de entrega de contenido de alto desempeño distribuido a escala mundial.</li>
            <li><b>Amazon Simple Queue Service (Amazon SQS). </b>Ofrece a la aplicación un sistema de colas seguro y de alto desempeño que le permite distribuir el trabajo entre los procesos de la aplicación de forma fiable.</li>
            <li><b>Amazon DevPay. </b>Es un sencillo servicio de administración de cuentas y facturación online que le permite vender aplicaciones que están integradas en AWS, o que se ejecutan sobre este servicio.</li>
          </ul>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Qualoom es Partner de Amazon Web Services</h2><hr></hr>
          <p>Con esta carta de presentación y siendo parte de una de las familias más importantes a nivel tecnológico, Qualoom aporta el conocimiento y la experiencia necesaria para asegurar el correcto gobierno de arquitecturas de servicios en Amazon Web Services. Además, Qualoom puede actuar como Reseller de servicios para ayudar a las empresas a aligerar trámites administrativos e implementar un flujo de caja más flexible.</p><br/>
          <h3>Infraestructuras Cloud en otras plataformas</h3>
          <div className="cloud-platform">
            <a href="/cloud-infraestructura/partner-microsoft-azure/" className="cloud-platform-icon"><img src={Icon9} alt="Partner Microsoft Azure"></img><p>Partner Microsoft Azure</p><div><span>Descubre más</span><FaArrowCircleRight size={15}/></div></a>
            <a href="/cloud-infraestructura/partner-google-cloud/" className="cloud-platform-icon"><img src={Icon10} alt="Partner Google Cloud"></img><p>Partner Google Cloud<br/>Platform y Reseller</p><div><span>Descubre más</span><FaArrowCircleRight size={15}/></div></a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PartnerAWS